import restApi from "../utils/setupAPI";

function stocksService() {
  return {
    getCompanyStockDetails: (id: string | number) =>
      restApi.request({
        method: "GET",
        url: `/companies/stock-details/`,
      }),
    issueStock: (id: string | number, data: any) =>
      restApi.request({
        method: "POST",
        url: `/transactions/shares/`,
        data,
      }),
    createTransfer: (id: string | number, data: any) =>
      restApi.request({
        headers: {
          "Content-Type": "multipart/form-data",
        },
        method: "POST",
        url: `/transactions/transfers/`,
        data,
      }),
    shareCreateTransfer: (id: string | number, data: any) =>
      restApi.request({
        headers: {
          "Content-Type": "multipart/form-data",
        },
        method: "POST",
        url: `/transactions/transfers/`,
        data,
      }),
    // TODO: remove and use getTransfers method
    shareListTransfers: () =>
      restApi.request({
        method: "GET",
        url: `/transactions/transfers/`,
      }),
    getStock: (id: string | number, stockId: string | number) =>
      restApi.request({
        method: "GET",
        url: `/transactions/shares/${stockId}/`,
      }),
    getShStockDetails: () =>
      restApi.request({
        method: "GET",
        url: `/shareholders/stock-info`,
      }),
    getTransfers: (limit: number, next: string | any = null) =>
      restApi.request({
        method: "GET",
        url: next || `/transactions/transfers/?limit=${limit}`,
      }),
    getTransfer: (id: string | number, transferId: string | number) =>
      restApi.request({
        method: "GET",
        url: `/transactions/transfers/${transferId}/`,
      }),
    getRelatedTransfers: (
      id: string | number,
      shareholderId: string | number
    ) =>
      restApi.request({
        method: "GET",
        url: `/shareholders/${shareholderId}/related-transfers`,
      }),
    approveTransfer: (id: string | number, transferId: string | number) =>
      restApi.request({
        method: "POST",
        url: `/transactions/transfers/${transferId}/approve/`,
      }),
    denyTransfer: (id: string | number, transferId: string | number) =>
      restApi.request({
        method: "POST",
        url: `/transactions/transfers/${transferId}/deny/`,
      }),
  };
}

export default stocksService;
