import restApi from "../utils/setupAPI";

function usersService() {
  return {
    getUsers: () =>
      restApi.request({
        method: "GET",
        url: `/accounts/`,
      }),
    createUser: (data: object) =>
      restApi.request({
        method: "POST",
        url: `/accounts/`,
        data: {
          user: data,
        },
      }),
    editUser: (id: string | number, data: object) =>
      restApi.request({
        method: "PUT",
        url: `/accounts/${id}/`,
        data,
      }),
    getUser: (id: string | number) =>
      restApi.request({
        method: "GET",
        url: `/accounts/${id}/`,
      }),
    activateUser: (id: string | number) =>
      restApi.request({
        method: "POST",
        url: `/accounts/${id}/activate-deactivate/`,
      }),
    deactivateUser: (id: string | number) =>
      restApi.request({
        method: "DELETE",
        url: `/accounts/${id}/activate-deactivate/`,
      }),
    globalInfo: () =>
      restApi.request({
        method: "GET",
        url: "/companies/global-info",
      }),
    uploadUserFiles: (id: string | number, data: object) =>
      restApi.request({
        method: "PATCH",
        url: `/accounts/${id}/upload-files/`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data,
      }),
    deleteUser: (id: number | string) =>
      restApi.request({
        method: "DELETE",
        url: `/accounts/${id}`,
      }),
  };
}

export default usersService;
