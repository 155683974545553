import { Grow, Fade } from "@material-ui/core";

const Transition = ({ type, visible, children, timeout, ...props }) => {
  const Tr = type === "Grow" ? Grow : Fade;

  return (
    <Tr
      in={visible}
      style={{ transformOrigin: "0 0 0" }}
      {...(visible ? { timeout: timeout } : {})}
    >
      {children}
    </Tr>
  );
};

export default Transition;
