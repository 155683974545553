import { Reducer } from "redux";
import _ from "lodash";

import { AppTypes } from "../app/types";
import { TransactionsState, TransactionsTypes } from "./types";

const INITIAL_STATE: TransactionsState = {
  loading: false,
  stock_details: {},
  stock: {},
  transfer: {},
  transfers: [],
  pagination: {},
  error: false,
};

const companyReducer: Reducer<TransactionsState> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case TransactionsTypes.GET.REQUEST:
    case TransactionsTypes.GET_RELATED_TRANSFER.REQUEST:
    case TransactionsTypes.CREATE.REQUEST:
    case TransactionsTypes.ISSUE_STOCK.REQUEST:
    case TransactionsTypes.DELETE.REQUEST:
    case TransactionsTypes.UPDATE.REQUEST:
    case TransactionsTypes.GET_DETAIL.REQUEST:
    case TransactionsTypes.TRANSFER.REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case TransactionsTypes.GET.SUCCESS: {
      const { results } = action.payload;
      delete action.payload.results;
      return {
        ...state,
        loading: false,
        error: false,
        transfers: _.unionBy(state.transfers, results, "id"),
        pagination: action.payload,
      };
    }
    case TransactionsTypes.GET_RELATED_TRANSFER.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        transfers: action.payload,
      };
    case TransactionsTypes.GET_STOCK_DETAIL.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        stock_details: action.payload,
      };
    case TransactionsTypes.GET_DETAIL.SUCCESS:
    case TransactionsTypes.TRANSFER.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        transfer: action.payload,
      };
    case TransactionsTypes.CREATE.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        transfer: action.payload,
      };
    case TransactionsTypes.ISSUE_STOCK.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case TransactionsTypes.DELETE.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case TransactionsTypes.UPDATE.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case TransactionsTypes.APPROVE_TRANSFER.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        transfer: {
          ...state.transfer,
          status: "APPROVED",
        },
      };
    case TransactionsTypes.DENY_TRANSFER.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        transfer: {
          ...state.transfer,
          status: "DENIED",
        },
      };
    case TransactionsTypes.GET.FAILURE:
    case TransactionsTypes.GET_RELATED_TRANSFER.FAILURE:
    case TransactionsTypes.GET_DETAIL.FAILURE:
    case TransactionsTypes.TRANSFER.FAILURE:
    case TransactionsTypes.CREATE.FAILURE:
    case TransactionsTypes.ISSUE_STOCK.FAILURE:
    case TransactionsTypes.DELETE.FAILURE:
    case TransactionsTypes.UPDATE.FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case AppTypes.REQUEST_TIMEDOUT:
      return {
        ...state,
        loading: false,
        error: false,
      };
    default:
      return state;
  }
};

export default companyReducer;
