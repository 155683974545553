import React from "react";
import { connect } from "react-redux";

import { AppTypes } from "../../../reducers/app/types";
import PageLoadError from "../PageLoadError";

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError(error) {
    console.warn("APP CRASHED", error);
  }

  componentDidCatch(error, errorInfo) {
    // TODO: register error to a log service
    console.warn("APP CRASHED", error, errorInfo);
    this.setState({
      hasError: true,
    });
    // this.props.sendPageError(error);
  }

  render() {
    if (this.state.hasError) return <PageLoadError />;
    return this.props.children;
  }
}

const mapDispatch = (dispatch) => ({
  sendPageError: (error) =>
    dispatch({ type: AppTypes.CONTENT_LOAD_ERROR, error: error }),
});

export default connect(null, mapDispatch)(ErrorBoundary);
