import { makeAsyncActionType } from "../utils";

const cpTypeGeneric = makeAsyncActionType("documents");

const GET = cpTypeGeneric("GET");
const CREATE = cpTypeGeneric("CREATE");
const DELETE = cpTypeGeneric("DELETE");
const UPDATE = cpTypeGeneric("UPDATE");

/**
 *
 * Actions Types
 *
 */

export const DocumentTypes = {
  UPDATE,
  DELETE,
  CREATE,
  GET,
};

/**
 *
 * Data Types
 *
 */

export interface Document {
  id: number | string;
  name: string;
  doc_type: number | string;
  document: string | null;
  company: string | number | object;
}
/**
 *
 * State Types
 *
 */

export interface DocumentState {
  loading: boolean;
  documents: Document[] | null;
  document: Document | null | object;
  error: boolean | string | any;
}

/**
 * Document thunk Types
 */

export interface UploadDocumentParams {
  name: string;
  doc_type: number | string;
  document: string | null;
}
