import restApi from "../utils/setupAPI";

const authService = () => {
  return {
    loadUser: () =>
      restApi.request({
        method: "GET",
        url: "/accounts/auth/user/",
      }),
    authLogin: (email: string, password: string) =>
      restApi.request({
        method: "POST",
        url: "/accounts/auth/login/",
        data: {
          email: email,
          password,
        },
      }),
    authLogout: () =>
      restApi.request({
        method: "GET",
        url: "/accounts/auth/logout/",
      }),
    resendVerificationEmail: (email: string) =>
      restApi.request({
        method: "POST",
        url: "/accounts/auth/resend-verification-email/",
        data: {
          email,
        },
      }),
    requestPassReset: (email: string) =>
      restApi.request({
        method: "POST",
        url: "/accounts/auth/password/reset/",
        data: {
          email,
        },
      }),
    resetPassword: (
      newPass1: string,
      confirmPass: string,
      uid: string,
      token: string
    ) =>
      restApi.request({
        method: "POST",
        url: `/accounts/auth/password/reset/confirm/${uid}/${token}/`,
        data: {
          new_password1: newPass1,
          new_password2: confirmPass,
          uid,
          token,
        },
      }),
    passwdChange: (oldPass: string, newPass1: string, newPass2: string) =>
      restApi.request({
        method: "POST",
        url: "/accounts/auth/password/change/",
        data: {
          old_password: oldPass,
          new_password1: newPass1,
          new_password2: newPass2,
        },
      }),
  };
};

export default authService;
