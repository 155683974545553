import axios from "axios";

import { API_ENDPOINT } from "../config";

/*
  create an instance for requests with axios
*/

const restApi = axios.create({
  baseURL: API_ENDPOINT,
  timeout: 100000,
  timeoutErrorMessage: "TIMEDOUT",
  headers: {
    "Content-Type": "application/json",
  },
});

export default restApi;
