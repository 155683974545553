import { Fragment, useEffect } from "react";
import { connect } from "react-redux";

import { loadUser } from "../reducers/app/thunks";
import { Loading } from "../components/common";
import { getUserInfo } from "../selectors/auth";

const AuthGuard = ({ children, loadUser, isAuthenticated, loadingApp }) => {
  useEffect(() => {
    loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loadingApp) return <Loading breathe />;
  return <Fragment>{children}</Fragment>;
};

const mapStateToProps = (state) => ({
  user: getUserInfo(state),
  loadingApp: state.app.loadingApp,
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = (dispatch) => ({
  loadUser: () => dispatch(loadUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthGuard);
