import { makeAsyncActionType } from "../utils";

const cpTypeGeneric = makeAsyncActionType("users");

const GET = cpTypeGeneric("GET");
const CREATE = cpTypeGeneric("CREATE");
const DELETE = cpTypeGeneric("DELETE");
const UPDATE = cpTypeGeneric("UPDATE");
const GET_DETAIL = cpTypeGeneric("GET_DETAIL");
const ACTIVATE = cpTypeGeneric("ACTIVATE");
const DEACTIVATE = cpTypeGeneric("DEACTIVATE");
const GLOBAL_INFO = cpTypeGeneric("GLOBAL_INFO");
const UPLOAD_USER_FILES = cpTypeGeneric("UPLOAD_USER_FILES");

/**
 *
 * Actions Types
 *
 */

export const UserTypes = {
  UPDATE,
  DELETE,
  CREATE,
  GET,
  GET_DETAIL,
  ACTIVATE,
  DEACTIVATE,
  GLOBAL_INFO,
  UPLOAD_USER_FILES,
};

/**
 *
 * Data Types
 *
 */

export interface User {
  id: number | string;
  country: string;
  state: string;
  city: string;
  zip_code: string;
  address1: string;
  email: string;
  phone_number: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  date_birth: string;
  address2: string;
  user: string;
  company: string;
}

/**
 *
 * State Types
 *
 */

export interface UserState {
  loading: boolean;
  user: User | object | null;
  users: User[] | object[] | null;
  globalInfo: object | null;
  error: boolean | string | any;
}

/**
 * User thunk Types
 */

export interface CreateUserParams {
  user: User;
}
