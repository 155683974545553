import { Reducer } from "redux";
import { AppTypes } from "../app/types";
import { CompanyState, CompanyTypes } from "./types";

const INITIAL_STATE: CompanyState = {
  loading: false,
  companies: [],
  company: {},
  globalInfo: {},
  graph: {},
  error: false,
};

const companyReducer: Reducer<CompanyState> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case CompanyTypes.GET.REQUEST:
    case CompanyTypes.CREATE.REQUEST:
    case CompanyTypes.DELETE.REQUEST:
    case CompanyTypes.UPDATE.REQUEST:
    case CompanyTypes.GET_DETAIL.REQUEST:
    case CompanyTypes.GLOBAL_INFO.REQUEST:
    case CompanyTypes.COMPANY_GRAPH.REQUEST:
    case CompanyTypes.UPLOAD_FILES.REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case CompanyTypes.GET.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        companies: action.payload,
      };
    case CompanyTypes.UPLOAD_FILES.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        company: {
          ...state.company,
          ...action.payload,
        },
      };
    case CompanyTypes.COMPANY_GRAPH.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        graph: action.payload,
      };
    case CompanyTypes.GET_DETAIL.SUCCESS:
    case CompanyTypes.CREATE.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        company: action.payload,
      };
    case CompanyTypes.DELETE.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        companies: state.companies?.filter((cp) => cp.id !== action.id),
      };
    case CompanyTypes.UPDATE.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        company: {
          ...state,
          ...action.payload.company,
        },
      };
    case CompanyTypes.ACTIVATE: {
      const cp = state.companies?.find(
        (cp) => cp.id === action.payload.companyId
      );
      const cps =
        state.companies?.filter((cp) => cp.id !== action.payload.companyId) ||
        [];

      return {
        ...state,
        loading: false,
        error: false,
        companies: [...cps, { ...cp, is_active: true }],
      };
    }
    case CompanyTypes.DEACTIVATE: {
      const cp = state.companies?.find(
        (cp) => cp.id === action.payload.companyId
      );
      const cps =
        state.companies?.filter((cp) => cp.id !== action.payload.companyId) ||
        [];

      return {
        ...state,
        loading: false,
        error: false,
        companies: [...cps, { ...cp, is_active: false }],
      };
    }

    case CompanyTypes.GLOBAL_INFO.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        globalInfo: action.payload,
      };

    case CompanyTypes.GET.FAILURE:
    case CompanyTypes.COMPANY_GRAPH.FAILURE:
    case CompanyTypes.GET_DETAIL.FAILURE:
    case CompanyTypes.GLOBAL_INFO.FAILURE:
    case CompanyTypes.CREATE.FAILURE:
    case CompanyTypes.DELETE.FAILURE:
    case CompanyTypes.UPDATE.FAILURE:
    case CompanyTypes.UPLOAD_FILES.FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case AppTypes.REQUEST_TIMEDOUT:
      return {
        ...state,
        loading: false,
        error: false,
      };
    default:
      return state;
  }
};

export default companyReducer;
