import {
  Typography,
  IconButton,
  DialogContent,
  DialogTitle,
  Dialog,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";

import { FaTimes } from "react-icons/fa";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    background: "var(--green-dark-1)",
  },
  "& .MuiDialogTitle-root .MuiTypography-root": {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <FaTimes />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const Modal = ({ open, onClose, title, children, ...props }) => {
  return (
    <BootstrapDialog onClose={onClose} open={open} {...props}>
      <BootstrapDialogTitle onClose={onClose}>
        <Typography>{title}</Typography>
      </BootstrapDialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </BootstrapDialog>
  );
};

export default Modal;
