import { makeActionType } from "../utils";

const toastTypeGeneric = makeActionType("toast");

export const ENQUEUE_SNACKBAR = toastTypeGeneric("ENQUEUE_SNACKBAR");
export const CLOSE_SNACKBAR = toastTypeGeneric("CLOSE_SNACKBAR");
export const REMOVE_SNACKBAR = toastTypeGeneric("REMOVE_SNACKBAR");

/**
 *
 * Actions Types
 *
 */

export const ToastTypes = {
  ENQUEUE_SNACKBAR,
  CLOSE_SNACKBAR,
  REMOVE_SNACKBAR,
};

/**
 *
 * Data Types
 *
 */

interface Option {
  variant?: "default" | "warning" | "success" | "error" | "info";
  action?: any;
  key?: string | number;
  autoHideDuration?: number;
  anchorOrigin?: object | null;
}

export interface Notification {
  message: string;
  persist?: boolean | null;
  options?: Option;
  key?: string | number;
  dismissed?: boolean;
}

/**
 *
 * State Types
 *
 */

export interface ToastState {
  notifications: Notification[];
}
