import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

import { getUserInfo } from "../selectors/auth";

export const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  user: { company_id },
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/auth",
            state: { redirectUrl: props.location.pathname },
          }}
        />
      )
    }
  />
);

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated || false,
  user: getUserInfo(state),
});

export default connect(mapStateToProps)(PrivateRoute);
