import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import logo from "../../../images/Logo.png";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";

function getColor(props) {
  const d = document.createElement("div");
  d.style.color = props.color;
  document.body.appendChild(d);
  const rgbcolor = window.getComputedStyle(d).color;
  const match =
    /rgba?\((\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*(,\s*\d+[.d+]*)*\)/g.exec(rgbcolor);
  const color = `${match[1]}, ${match[2]}, ${match[3]}`;
  return color;
}

export const ContainerLoading = styled.div`
  margin: auto;
  max-width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--blue-dark-1);
`;

const RotateSpin = styled.div`
  animation: ${(props) => `spinner ${props.duration}s infinite linear`};
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  border: ${(props) => `0.4em solid rgba(${getColor(props)}, 0.2)`};
  border-left: ${(props) => `0.4em solid ${props.color}`};
  border-radius: 50%;
  font-size: ${(props) => `${props.size}px`};
  height: 3em;
  margin: 5px auto;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  width: 3em;

  &:after {
    border-radius: 50%;
    height: 0.5em;
    width: 0.5em;
  }
`;

const BreathLoading = styled.div`
  & > :first-child {
    width: 200px;
    height: 200px;
    animation: breathe 1s linear infinite;
  }

  @keyframes breathe {
    0% {
      background-size: 60% auto;
    }
    50% {
      background-size: 100% auto;
    }
    100% {
      background-size: 60% auto;
    }
  }
`;

const RotateSpinLoader = (props) => <RotateSpin {...props} />;

RotateSpinLoader.propTypes = {
  color: PropTypes.string,
  duration: PropTypes.number,
  size: PropTypes.number,
};

RotateSpinLoader.defaultProps = {
  color: "#fff",
  duration: 1.1,
  size: 10,
};

const breatheStyle = {
  background: `url(${logo}) center center no-repeat`,
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1350,
    color: "#fff",
  },
}));

const Loading = ({ breathe, backdrop, loading, ...props }) => {
  const classes = useStyles();

  if (backdrop) {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }
  return breathe ? (
    <ContainerLoading>
      <BreathLoading>
        <div style={breatheStyle} />
      </BreathLoading>
    </ContainerLoading>
  ) : (
    <RotateSpinLoader />
  );
};

export default Loading;
