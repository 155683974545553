import thunk from "redux-thunk";
import { createStore, applyMiddleware, Store, compose } from "redux";

import rootReducer from "../reducers";
import { AuthState } from "../reducers/auth/types";
import saveTokenMiddleware from "../middlewares/saveToken";
import { AppState } from "../reducers/app/types";
import { CompanyState } from "../reducers/companies/types";
import { UserState } from "../reducers/users/types";
import { ToastState } from "../reducers/toast/types";
import { SettingsState } from "../reducers/settings/types";
import { ShareholderState } from "../reducers/shareholders/types";
import { DocumentState } from "../reducers/documents/types";
import { NetworkService } from "../services";

export interface ApplicationState {
  auth: AuthState;
  app: AppState;
  users: UserState;
  toast: ToastState;
  companies: CompanyState;
  documents: DocumentState;
  shareholders: ShareholderState;
  settings: SettingsState;
}

const middlewares = [thunk, saveTokenMiddleware];
const composeEnhancers =
  (typeof window !== "undefined" &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;
const store: Store<ApplicationState> = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);

NetworkService(store.dispatch);

export default store;
