import { Reducer } from "redux";
import _ from "lodash";

import { AppTypes } from "../app/types";
import { ShareholderState, ShareholderTypes } from "./types";

const INITIAL_STATE: ShareholderState = {
  loading: false,
  sh_register_modal_opened: false,
  shareholders: [],
  pagination: {},
  shareholder: {
    user: {},
  },
  globalInfo: {},
  errors: null,
  registrations: [],
  searching: false,
  shRegistrationsloading: false,
};

const shareholderReducer: Reducer<ShareholderState> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case ShareholderTypes.GET.REQUEST:
    case ShareholderTypes.CREATE.REQUEST:
    case ShareholderTypes.DELETE.REQUEST:
    case ShareholderTypes.UPDATE.REQUEST:
    case ShareholderTypes.GET_DETAIL.REQUEST:
    case ShareholderTypes.GLOBAL_INFO.REQUEST:
    case ShareholderTypes.REGISTER_SH.REQUEST:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    // set searching to true. Do not get more data when table is updated.
    case ShareholderTypes.SHAREHOLDERS_SEARCH.REQUEST:
      return {
        ...state,
        searching: true,
      };
    case ShareholderTypes.SH_REGISTRATIONS_SEARCH.REQUEST:
      return {
        ...state,
        shRegistrationsloading: true,
      };
    case ShareholderTypes.REGISTER_SH_OPEN_MODAL:
      return {
        ...state,
        loading: false,
        errors: false,
        sh_register_modal_opened: true,
      };
    case ShareholderTypes.REGISTER_SH_CLOSE_MODAL:
      return {
        ...state,
        loading: false,
        errors: false,
        sh_register_modal_opened: false,
      };
    case ShareholderTypes.REGISTER_SH.SUCCESS:
      return {
        ...state,
        loading: false,
        errors: false,
        sh_register: action.payload,
      };

    case ShareholderTypes.SH_REGISTRATIONS_OPEN_MODAL:
      return {
        ...state,
        loading: false,
        errors: false,
        sh_registrations_modal_opened: true,
      };
    case ShareholderTypes.SH_REGISTRATIONS_CLOSE_MODAL:
      return {
        ...state,
        loading: false,
        errors: false,
        sh_registrations_modal_opened: false,
      };
    case ShareholderTypes.SH_REGISTRATIONS_SEARCH.SUCCESS:
      return {
        ...state,
        errors: false,
        registrations: action.payload.results,
      };
    case ShareholderTypes.SH_REGISTRATIONS.SUCCESS: {
      const { results } = action.payload;
      delete action.payload.results;
      return {
        ...state,
        loading: false,
        shRegistrationsloading: false,
        errors: false,
        registrations: _.unionBy(state.registrations, results, "id"),
        sh_register_pagination: action.payload,
      };
    }
    case ShareholderTypes.SH_REGISTRATIONS_EXTEND_TOKEN.SUCCESS:
      const regs = state.registrations.filter(
        (r: object | any) => r.email !== action.payload.email
      );
      return {
        ...state,
        loading: false,
        errors: false,
        registrations: [action.payload, ...regs],
      };
    case ShareholderTypes.SH_REGISTRATIONS_RESEND_EMAIL.SUCCESS:
      // TODO: what to do?
      return {
        ...state,
        loading: false,
        errors: false,
      };
    case ShareholderTypes.GET.SUCCESS: {
      const { results } = action.payload;
      delete action.payload.results;
      return {
        ...state,
        loading: false,
        errors: null,
        shareholders: _.unionBy(state.shareholders, results, "id"),
        pagination: action.payload,
      };
    }
    case ShareholderTypes.GET_DETAIL.SUCCESS:
      return {
        ...state,
        loading: false,
        errors: null,
        shareholder: action.payload,
      };
    case ShareholderTypes.CREATE.SUCCESS:
      return {
        ...state,
        loading: false,
        errors: null,
        shareholder: action.payload,
        sh_created: true,
      };
    case ShareholderTypes.DELETE.SUCCESS:
      return {
        ...state,
        loading: false,
        errors: null,
        shareholders: state.shareholders?.filter(
          (sh) => sh.id !== action.payload.id
        ),
      };
    case ShareholderTypes.UPDATE.SUCCESS:
      return {
        ...state,
        loading: false,
        errors: null,
        shareholder: {
          ...state,
          ...action.payload.shareholder,
        },
      };
    case ShareholderTypes.ACTIVATE: {
      const sh = state.shareholders?.find(
        (sh) => sh.id === action.payload.companyId
      );
      const cps =
        state.shareholders?.filter(
          (sh) => sh.id !== action.payload.companyId
        ) || [];

      return {
        ...state,
        loading: false,
        errors: null,
        shareholders: [...cps, { ...sh, is_active: true }],
      };
    }
    case ShareholderTypes.DEACTIVATE: {
      const sh = state.shareholders?.find(
        (sh) => sh.id === action.payload.companyId
      );
      const cps =
        state.shareholders?.filter(
          (sh) => sh.id !== action.payload.companyId
        ) || [];

      return {
        ...state,
        loading: false,
        errors: null,
        shareholders: [...cps, { ...sh, is_active: false }],
      };
    }
    case ShareholderTypes.SHARE_ACTIVATE:
      return {
        ...state,
        loading: false,
        errors: null,
        shareholder: {
          ...state.shareholder,
          is_active: true,
          user: {
            ...state.shareholder?.user,
            is_active: true,
          },
        },
      };
    case ShareholderTypes.SHARE_DEACTIVATE:
      return {
        ...state,
        loading: false,
        errors: null,
        shareholder: {
          ...state.shareholder,
          is_active: false,
          user: {
            ...state.shareholder?.user,
            is_active: false,
          },
        },
      };
    case ShareholderTypes.GLOBAL_INFO.SUCCESS:
      return {
        ...state,
        loading: false,
        errors: null,
        globalInfo: action.payload,
      };
    case ShareholderTypes.SHAREHOLDERS_SEARCH.SUCCESS:
      return {
        ...state,
        loading: false,
        errors: null,
        shareholders: action.payload.results,
      };
    case ShareholderTypes.SHAREHOLDERS_ASYNC_SEARCH.SUCCESS:
      return {
        ...state,
        loading: false,
        errors: null,
        asyncShareholders: action.payload.results,
      };
    case ShareholderTypes.UPDATE.FAILURE:
      return {
        ...state,
        loading: false,
        errors: {
          ...state.errors,
          ...action.payload,
        },
      };
    case ShareholderTypes.CREATE.FAILURE:
      return {
        ...state,
        loading: false,
        errors: {
          ...state.errors,
          ...action.payload.shareholder,
          email: action.payload.email,
          password1: action.payload.password1,
          password2: action.payload.password2,
        },
      };
    case ShareholderTypes.DELETE.FAILURE:
    case ShareholderTypes.GET.FAILURE:
    case ShareholderTypes.GET_DETAIL.FAILURE:
    case ShareholderTypes.GLOBAL_INFO.FAILURE:
    case ShareholderTypes.SHAREHOLDERS_SEARCH.FAILURE:
    case ShareholderTypes.REGISTER_SH.FAILURE:
    case ShareholderTypes.SH_REGISTRATIONS.FAILURE:
      return {
        ...state,
        loading: false,
        errors: true,
        searching: false,
      };
    case ShareholderTypes.SH_REGISTRATIONS_SEARCH.FAILURE:
      return {
        ...state,
        shRegistrationsloading: false,
        errors: true,
      };
    case AppTypes.REQUEST_TIMEDOUT:
      return {
        ...state,
        loading: false,
        error: false,
      };
    default:
      return state;
  }
};

export default shareholderReducer;
