import React, { useRef, useCallback } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

const InputForm = styled.input`
  padding: 10px;
  font-size: 14px;
  line-height: 1.5;
  display: block;
  width: 100%;
  color: var(--white-100);
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  overflow: hidden;
  border: none;

  ${(props) =>
    props.rounded &&
    css`
      border-radius: 40px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    `}
`;

const InputContainer = styled.div`
  flex: 1;
  background: #f9fcff;
  box-sizing: border-box;
  backdrop-filter: blur(4px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 6px;
  overflow: hidden;

  ${(props) =>
    props.rounded &&
    css`
      border-radius: 40px;
    `}

  .input-group {
    position: relative;
    display: flex;
    align-items: stretch;
    width: 100%;
  }
`;

const GroupPrepend = styled.div`
  display: flex;
  margin-right: -1px;

  button {
    border: none;
    padding: 5px 10px;
    z-index: 2;
    color: #9aa1b9;
    display: inline-block;
    user-select: none;
    background-color: var(--blue-bold);
    border: 1px solid transparent;

    :not(:disabled) {
      cursor: pointer;
    }
  }
`;

const Label = styled.p`
  font-size: 12px;
  font-weight: 600;
  margin: 5px 5px 10px;
  color: var(--white-100);
`;

const Input = (props) => {
  const ref = useRef();

  const handleBtnClick = useCallback(() => {
    ref.current.focus();
  }, [ref]);

  return (
    <>
      {props.label && <Label>{props.label}</Label>}
      <InputContainer rounded={props.rounded} style={props.style}>
        {props.icon ? (
          <GroupPrepend>
            {/* TODO: CHECK IF IS A VALID DOM ELEMENT */}
            <button type="button" onClick={handleBtnClick}>
              {props.icon}
            </button>
            <InputForm
              ref={ref}
              placeholder={props.placeholder}
              type={props.type}
              onChange={props.onChange}
              onClick={props.onClick}
              onKeyPress={props.onKeyPress}
              required={props.required}
              name={props.name}
              value={props.value}
              rounded={props.rounded}
            />
          </GroupPrepend>
        ) : (
          <InputForm
            ref={ref}
            placeholder={props.placeholder}
            type={props.type}
            onChange={props.onChange}
            onClick={props.onClick}
            onKeyPress={props.onKeyPress}
            required={props.required}
            name={props.name}
            green={props.green}
            value={props.value}
            rounded={props.rounded}
          />
        )}
      </InputContainer>
    </>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  required: PropTypes.bool,
};

export default Input;
