import { Reducer } from "redux";
import { AppTypes } from "../app/types";
import { UserTypes } from "../users/types";
import { AuthState, AuthTypes } from "./types";

const INITIAL_STATE: AuthState = {
  isAuthenticated: false,
  loading: false,
  user: {},
  error: null,
};

const authReducer: Reducer<AuthState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AuthTypes.LOAD.REQUEST:
    case AuthTypes.LOGIN.REQUEST:
    case AuthTypes.REGISTER.REQUEST:
      return {
        ...state,
        isAuthenticated: false,
        loading: true,
        error: null,
      };
    case AuthTypes.RESEND_VERIFICATION_EMAIL.REQUEST:
    case AuthTypes.REQUEST_RESET_PASSWORD.REQUEST:
    case AuthTypes.RESET_PASSWORD.REQUEST:
    case AuthTypes.CHANGE_PASSWORD.REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case AuthTypes.CHANGE_PASSWORD.SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case AuthTypes.RESEND_VERIFICATION_EMAIL.SUCCESS:
      return {
        ...state,
        loading: false,
        resent_success: true,
        error: null,
      };
    case AuthTypes.EMAIL_VERIFICATION_SENT:
      return {
        ...state,
        email_verification_sent: true,
      };
    case AuthTypes.CLOSE_EMAIL_VERIFICATION_SENT:
      return {
        ...state,
        email_verification_sent: false,
        resent_success: false,
      };
    case AuthTypes.RESET_PASSWORD.SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        passwordReseted: true,
      };
    case AuthTypes.REQUEST_RESET_PASSWORD.SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        passwordResetSent: true,
      };
    case AuthTypes.LOAD.SUCCESS:
    case AuthTypes.LOGIN.SUCCESS:
    case AuthTypes.REGISTER.SUCCESS:
    case AppTypes.LOAD.SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: action.payload.user,
        error: null,
      };
    case UserTypes.UPLOAD_USER_FILES.SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    case AuthTypes.AUTH_LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
      };
    case AuthTypes.RESEND_VERIFICATION_EMAIL.FAILURE:
    case AuthTypes.CHANGE_PASSWORD.FAILURE:
      return {
        ...state,
        loading: false,
        resent_success: false,
      };
    case AuthTypes.RESET_PASSWORD.FAILURE:
      return {
        ...state,
        loading: false,
        error: null,
        passwordResetSent: false,
        passwordReseted: false,
      };
    case AuthTypes.LOGIN.FAILURE:
      let message =
        action.payload.message ||
        action.payload.detail ||
        action.payload.non_field_errors[0] ||
        "";
      if (message === "E-mail is not verified.") {
        return {
          ...state,
          isAuthenticated: false,
          loading: false,
        };
      }
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        error: message,
      };
    case AuthTypes.OPEN_FORGOT_PASS:
      return {
        ...state,
        isForgotPassModalOpened: true,
      };
    case AuthTypes.CLOSE_FORGOT_PASS:
      return {
        ...state,
        passwordResetSent: false,
        isForgotPassModalOpened: false,
      };
    case AuthTypes.LOAD.FAILURE:
    case AuthTypes.REGISTER.FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        error: true,
      };
    case AppTypes.REQUEST_TIMEDOUT:
      return {
        ...state,
        loading: false,
        error: false,
      };
    default:
      return state;
  }
};

export default authReducer;
