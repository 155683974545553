import { Reducer } from "redux";
import { AppTypes } from "../app/types";
import { SettingsState, CompanyTypes } from "./types";

const INITIAL_STATE: SettingsState = {
  loading: false,
  allow_sh_transfer_shares: false,
  show_cp_logo: false,
  show_cp_name: false,
  error: false,
};

const companyReducer: Reducer<SettingsState> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case CompanyTypes.GET.REQUEST:
    case CompanyTypes.UPDATE.REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case CompanyTypes.GET.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        ...action.payload,
      };

    case CompanyTypes.UPDATE.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        ...action.payload,
      };
    case CompanyTypes.GET.FAILURE:
    case CompanyTypes.UPDATE.FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case AppTypes.REQUEST_TIMEDOUT:
      return {
        ...state,
        loading: false,
        error: false,
      };
    default:
      return state;
  }
};

export default companyReducer;
