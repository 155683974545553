import { Box, makeStyles, Typography } from "@material-ui/core";

const tabStyles = makeStyles({
  root: {
    ".MuiTabs-root": {
      margin: "0 0 40px",
    },
    "& .MuiTab-textColorPrimary": {
      textTransform: "none",
      fontSize: 16,
      fontWeight: 600,
      color: "#c6ced6",
    },
    "& .MuiTabs-indicator": {
      color: "#c6ced6",
    },
    "& .MuiTab-textColorPrimary.Mui-selected": {},
  },
});

export default function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = tabStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      className={classes.root}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
