import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from "../../images/Logo.png";

const Container = styled.div`
  width: 150px;
  cursor: pointer;
  flex: 0 0 210px;

  img {
    width: 100%;
    height: auto;
  }
`;

export const Logo = () => (
  <Link to="/">
    <Container>
      <img src={logo} alt=" Corpotal logo" />
    </Container>
  </Link>
);

export default Logo;
