import { Dispatch } from "redux";

import {
  checkAuthentication,
  checkErrorMessage,
  checkRequestTimeout,
} from "../reducers/utils/auth";
import restApi from "../utils/setupAPI";

export const NetworkService = (dispatch: Dispatch) => {
  restApi.interceptors.request.use((config) => {
    const token = window.localStorage.getItem("token");

    let url = config.url || "";
    let st =
      url.indexOf("auth/login") >= 0 ||
      url.indexOf("auth/register") >= 0 ||
      url.indexOf("auth/password/reset") >= 0 ||
      url.indexOf("auth/sh_register") >= 0;

    if (token && !st) {
      config.headers = {
        Authorization: `JWT ${token}`,
      };
    }

    return config;
  });

  restApi.interceptors.response.use(
    (response) => response,
    (error: any) => {
      // const origRequest = error.config;

      // Prevent infinite loop
      // if (
      //   error.response.status === 401 &&
      //   origRequest.url === API_ENDPOINT + "/auth/user/"
      // ) {
      //   window.location.href = "/auth/";
      //   return Promise.reject(error);
      // }

      // if (error.response.status === 401) {
      // const refreshToken = localStorage.getItem("token") || "";

      // const tokenParts = JSON.parse(atob(refreshToken.split(".")[1]));

      // // exp date in token is expressed in seconds, while now() returns milliseconds:
      // const now = Math.ceil(Date.now() / 1000);
      // console.log(tokenParts.exp);

      //   if (tokenParts.exp > now) {
      //     return restApi
      //       .post("/auth/token-refresh/", { refresh: refreshToken })
      //       .then((response) => {
      //         localStorage.setItem("refresh_token", response.data.token);

      //         origRequest.headers["Authorization"] = "JWT " + response.data.token;

      //         return restApi(origRequest);
      //       })
      //       .catch((err) => {
      //         console.log(err);
      //       });
      //   } else {
      //     console.log("Refresh token is expired", tokenParts.exp, now);
      //     window.location.href = "/auth/";
      //   }
      // } else {
      //   console.log("Refresh token not available.");
      //   window.location.href = "/auth/";
      // }

      checkRequestTimeout(error, dispatch);

      // Error 😨
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */

        checkAuthentication(error, dispatch);
        checkErrorMessage(error, dispatch);
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
      } else {
        // Something happened in setting up the request and triggered an Error
      }
      // specific error handling done elsewhere
      return Promise.reject(error);
    }
    // }
  );
};

export { default as auth } from "./auth";
export { default as companies } from "./companies";
export { default as transactions } from "./transactions";
export { default as users } from "./users";
