let endpoint;

if (process.env.NODE_ENV === "production") {
  // endpoint = "https://cors-everywhere-me.herokuapp.com/http://corpotal-env.eba-numvfxiv.us-west-1.elasticbeanstalk.com/";
  endpoint = "https://api.corpotal.com";
} else {
  if (window.location.hostname.startsWith("192."))
    //endpoint = `http://${window.location.hostname}:8000`;
    endpoint = `http://192.168.1.105:8000`;
  else endpoint = "http://127.0.0.1:8000";
}

export const API_ENDPOINT = endpoint;
export const APP_ENDPOINT = "https://corpotal.com";
