import { Dispatch } from "redux";

import { auth } from "../../services";
import { AppTypes } from "./types";
import * as actions from "./actions";
import { performToken } from "../utils";

export const loadUser = () => {
  return async (dispatch: Dispatch | any) => {
    const token = performToken();

    if (token === null) {
      dispatch({
        type: AppTypes.LOAD.FAILURE,
      });
      return;
    } else {
      dispatch(actions.appRequest(AppTypes.LOAD.REQUEST));
      try {
        const response = await auth().loadUser();

        if (response.status === 200) {
          dispatch(actions.appSuccess(response.data, AppTypes.LOAD.SUCCESS));
        }
      } catch (error: any) {
        dispatch({
          type: AppTypes.LOAD.FAILURE,
          error,
        });
      }
    }
  };
};
