import { makeActionType, makeAsyncActionType } from "../utils";

const cpTypeGeneric = makeAsyncActionType("shareholders");
const cpType = makeActionType("shareholders");

const GET = cpTypeGeneric("GET");
const CREATE = cpTypeGeneric("CREATE");
const DELETE = cpTypeGeneric("DELETE");
const UPDATE = cpTypeGeneric("UPDATE");
const GET_DETAIL = cpTypeGeneric("GET_DETAIL");
const ACTIVATE = cpType("ACTIVATE");
const DEACTIVATE = cpType("DEACTIVATE");
const GLOBAL_INFO = cpTypeGeneric("GLOBAL_INFO");
const SHARE_ACTIVATE = cpType("SHARE_ACTIVATE");
const SHARE_DEACTIVATE = cpType("SHARE_DEACTIVATE");
const SHAREHOLDERS_SEARCH = cpTypeGeneric("SHAREHOLDERS_SEARCH");
const SHAREHOLDERS_ASYNC_SEARCH = cpTypeGeneric("SHAREHOLDERS_ASYNC_SEARCH");
const REGISTER_SH = cpTypeGeneric("REGISTER_SH");
const SH_REGISTRATIONS = cpTypeGeneric("SH_REGISTRATIONS");
const SH_REGISTRATIONS_SEARCH = cpTypeGeneric("SH_REGISTRATIONS_SEARCH");
const SH_REGISTRATIONS_EXTEND_TOKEN = cpTypeGeneric(
  "SH_REGISTRATIONS_EXTEND_TOKEN"
);
const SH_REGISTRATIONS_RESEND_EMAIL = cpTypeGeneric(
  "SH_REGISTRATIONS_RESEND_EMAIL"
);
const REGISTER_SH_OPEN_MODAL = cpType("REGISTER_SH_OPEN_MODAL");
const REGISTER_SH_CLOSE_MODAL = cpType("REGISTER_SH_CLOSE_MODAL");
const SH_REGISTRATIONS_OPEN_MODAL = cpType("SH_REGISTRATIONS_OPEN_MODAL");
const SH_REGISTRATIONS_CLOSE_MODAL = cpType("SH_REGISTRATIONS_CLOSE_MODAL");

/**
 *
 * Actions Types
 *
 */

export const ShareholderTypes = {
  UPDATE,
  DELETE,
  CREATE,
  GET,
  GET_DETAIL,
  ACTIVATE,
  DEACTIVATE,
  REGISTER_SH,
  SH_REGISTRATIONS,
  SH_REGISTRATIONS_SEARCH,
  SH_REGISTRATIONS_EXTEND_TOKEN,
  SH_REGISTRATIONS_RESEND_EMAIL,
  REGISTER_SH_OPEN_MODAL,
  REGISTER_SH_CLOSE_MODAL,
  SH_REGISTRATIONS_OPEN_MODAL,
  SH_REGISTRATIONS_CLOSE_MODAL,
  SHARE_ACTIVATE,
  SHARE_DEACTIVATE,
  GLOBAL_INFO,
  SHAREHOLDERS_SEARCH,
  SHAREHOLDERS_ASYNC_SEARCH,
};

/**
 *
 * Data Types
 *
 */

export interface Shareholder {
  id: number | string;
  country: string;
  state: string;
  city: string;
  zip_code: string;
  address: string;
  phone_number: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  date_birth: string;
  address2: string;
  user: string | object | any;
  all_number_shares: number | string;
  relationship: number | string;
  certificate_number: number | string;
  entity_name: string;
  is_entity: boolean;
  company: string | object;
}

export interface Pagination {
  count: number;
  next: string;
  previous: string | null;
  results: Array<Shareholder | any>;
}

/**
 *
 * State Types
 *
 */

export interface ShareholderState {
  [x: string]: any;
  loading: boolean;
  shareholders: Array<Shareholder>;
  shareholder: Shareholder | null | { user: {} };
  globalInfo: object | null;
  sh_register_modal_opened: boolean;
  errors: boolean | string | any;
  pagination: Pagination | any;
  searching: boolean;
  shRegistrationsloading: boolean;
}
