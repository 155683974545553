import styled from "styled-components";

import warning from "../../../images/warning.svg";

const Container = styled.div`
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: var(--blue-dark-1);
`;

const ErrorMessage = styled.p`
  font-size: 14px;
  color: var(--white-100);
  margin-bottom: 30px;
`;

const ButtonRefresh = styled.button`
  border-radius: 40px;
  border: 1px solid var(--blue-60);
  padding: 10px 24px;
  color: var(--blue-75);
  margin-top: 30px;
  background: transparent;

  :hover {
    transition: all 200ms ease-in-out;
    background: var(--blue-bold);
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  max-width: 300px;
  max-height: 300px;
  object-fit: cover;
`;

const PageLoadError = () => {
  const refreshPage = () => {
    window.location.reload();
  };
  return (
    <Container>
      <ErrorMessage>Sorry! Something went wrong</ErrorMessage>
      <div>
        {" "}
        <Image src={warning} />
      </div>
      <ButtonRefresh onClick={refreshPage}>Refresh page</ButtonRefresh>
    </Container>
  );
};

export default PageLoadError;
