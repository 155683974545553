import { Suspense } from "react";
import { Route, Router, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";

import history from "../../history";
import store from "../../store";
import GlobalStyles from "../Styles/globalStyles";
import AuthGuard from "../../auth";
import MainRoutes from "../../routes";
import { Loading } from "../common";
import Toast from "../Toast";
import ErrorBoundaries from "../Errors/ErrorBoundaries";

const MuiTheme = createTheme({
  palette: {
    type: "dark",
  },
  root: {
    color: "var(--white-100)",
  },
  typography: {
    allVariants: {
      color: "var(--white-100)",
    },
  },
});

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={MuiTheme}>
        <SnackbarProvider>
          <Toast />
          <GlobalStyles />
          <ErrorBoundaries>
            <Router history={history}>
              <Suspense fallback={<Loading breathe />}>
                <Switch>
                  <Route>
                    <AuthGuard>
                      <MainRoutes />
                    </AuthGuard>
                  </Route>
                </Switch>
              </Suspense>
            </Router>
          </ErrorBoundaries>
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
