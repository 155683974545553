import { FaCaretLeft } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
  max-width: max-content;

  svg {
    font-size: 22px;
  }

  & * {
    color: var(--blue);
  }
`;

const BackTo = ({ name }) => {
  const history = useHistory();

  const back = (e) => {
    e.stopPropagation();
    history.goBack();
  };

  return (
    <Container onClick={back}>
      <FaCaretLeft />
      <span>Back {name && `to ${name}`}</span>
    </Container>
  );
};

export default BackTo;
