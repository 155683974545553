import { action } from "typesafe-actions";
import { AuthTypes, User } from "./types";

export const authRequest = (actionType: string) => action(actionType);
export const authSuccess = (token: string, user: User, actionType: string) =>
  action(actionType, { token, user });

export const authFailure = (
  actionType: string,
  error: boolean | string | any = false
) => action(actionType, error);

export const authLogout = () => action(AuthTypes.AUTH_LOGOUT);

export const passwordResetRequest = () =>
  action(AuthTypes.RESET_PASSWORD.REQUEST);

export const passwordResetSuccess = (data: string | object | any) =>
  action(AuthTypes.RESET_PASSWORD.SUCCESS, data);

export const passwordResetFailure = (error: string | any) =>
  action(AuthTypes.RESET_PASSWORD.FAILURE, error);
