import { Reducer } from "redux";
import { ToastTypes, ToastState } from "./types";

const initialState = {
  notifications: [],
};

const toastReducer: Reducer<ToastState> = (state = initialState, action) => {
  switch (action.type) {
    case ToastTypes.ENQUEUE_SNACKBAR: {
      if (
        state.notifications[0] &&
        state.notifications[0].message === action.notification.message
      )
        return state;

      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key: action.key,
            ...action.notification,
          },
        ],
      };
    }
    case ToastTypes.CLOSE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.map((notification) =>
          action.dismissAll || notification.key === action.key
            ? { ...notification, dismissed: true }
            : { ...notification }
        ),
      };

    case ToastTypes.REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.key !== action.key
        ),
      };

    default:
      return state;
  }
};

export default toastReducer;
