import restApi from "../utils/setupAPI";

function companiesService() {
  return {
    listCompanies: () =>
      restApi.request({
        method: "GET",
        url: "/companies/",
      }),
    getShareholders: (limit: number, next: string | null = null) =>
      restApi.request({
        method: "GET",
        url: next || `/shareholders/?limit=${limit}`,
      }),
    createCompany: (data: any) =>
      restApi.request({
        method: "POST",
        url: "/companies/",
        data,
      }),
    getCompany: (id: string | number) =>
      restApi.request({
        method: "GET",
        url: `/companies/${id}/`,
      }),
    editCompany: (id: string | number, data: object) =>
      restApi.request({
        method: "PUT",
        url: `/companies/${id}/`,
        data,
      }),
    activateCompany: (id: string | number) =>
      restApi.request({
        method: "POST",
        url: `/companies/${id}/activate-deactivate/`,
      }),
    deactivateCompany: (id: string | number) =>
      restApi.request({
        method: "DELETE",
        url: `/companies/${id}/activate-deactivate/`,
      }),
    changeShareholderStatus: (id: string | number) =>
      restApi.request({
        method: "GET",
        url: `/shareholders/${id}/activate-deactivate/`,
      }),
    changeCompanyStatus: (id: string | number) =>
      restApi.request({
        method: "GET",
        url: `/companies/${id}/activate-deactivate/`,
      }),
    addShareholder: (data: object) =>
      restApi.request({
        method: "POST",
        url: `/shareholders/`,
        data: {
          shareholder: data,
        },
      }),
    getShareholder: (id: string | number) =>
      restApi.request({
        method: "GET",
        url: `/shareholders/${id}/`,
      }),
    editShareholder: (id: string | number, data: object) =>
      restApi.request({
        method: "PUT",
        url: `/shareholders/${id}/`,
        data,
      }),
    globalInfo: () =>
      restApi.request({
        method: "GET",
        url: `/companies/active-info/`,
      }),
    getCompanyGlobalInfo: () =>
      restApi.request({
        method: "GET",
        url: "/companies/global-info/",
      }),
    getGlobalShareholderInfo: () =>
      restApi.request({
        method: "GET",
        url: `/shareholders/global-info/`,
      }),
    createUser: (id: string | number, data: object) =>
      restApi.request({
        method: "POST",
        url: `/companies/${id}/users/`,
        data: {
          user: data,
        },
      }),
    getDocuments: () =>
      restApi.request({
        method: "GET",
        url: `/documents/`,
      }),
    uploadDocuments: (data: object) =>
      restApi.request({
        method: "POST",
        url: `/documents/`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data,
      }),
    uploadShareholderFiles: (id: string | number, data: object) =>
      restApi.request({
        method: "PATCH",
        url: `/shareholders/${id}/`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: {
          shareholder: data,
        },
      }),
    uploadCompanyFiles: (data: object) =>
      restApi.request({
        method: "PATCH",
        url: `/companies/upload-files/`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data,
      }),
    getCompanyGraph: () =>
      restApi.request({
        method: "GET",
        url: "/companies/graph/",
      }),
    getCompanyMinorDetails: () =>
      restApi.request({
        method: "GET",
        url: "/companies/minor-details/",
      }),
    getCompanySettings: () =>
      restApi.request({
        method: "GET",
        url: `/companies/settings/`,
      }),
    updateCompanySettings: (data: object) =>
      restApi.request({
        method: "PUT",
        url: `/companies/settings/`,
        data,
      }),
    searchCompanyUsers: (prefix: string) =>
      restApi.request({
        method: "GET",
        url: `/accounts/?search=${prefix}`,
      }),
    searchShareholders: (prefix: string) =>
      restApi.request({
        method: "GET",
        // TODO: dynamically set limit (from default table rows per page)
        url: `/shareholders/?search=${prefix}&limit=100`,
      }),
    registerSh: (data: object) =>
      restApi.request({
        method: "POST",
        url: "/shareholders/register/",
        data,
      }),
    getSHRegistrations: (limit: number, next: string | null = null) =>
      restApi.request({
        method: "GET",
        url: next || `/shareholders/register/?limit=${limit}`,
      }),
    searchSHRegistrations: (prefix: string) =>
      restApi.request({
        method: "GET",
        // TODO: dynamically set limit (from default table rows per page)
        url: `/shareholders/register/?search=${prefix}&limit=100`,
      }),
    shExtendToken: (token: string) =>
      restApi.request({
        method: "GET",
        url: `/shareholders/register/${token}/extend-token`,
      }),
    shRegistrationResendEmail: (token: string) =>
      restApi.request({
        method: "GET",
        url: `/shareholders/register/${token}/resend-email`,
      }),
  };
}

export default companiesService;
