import { Dispatch } from "redux";
import { appFailure } from "../app/actions";
import { AuthTypes } from "../auth/types";
import { AppTypes } from "../app/types";

import { authLogout } from "../auth/actions";
import { enqueueSnackbar } from "../toast/actions";

export const checkAuthentication = (error: any, dispatch: Dispatch) => {
  if (error.response.status === 401) {
    dispatch(authLogout());
    // TODO: reset state after logout
    dispatch(
      enqueueSnackbar({
        message: "Session Expired. Login again to get access",
        options: {
          autoHideDuration: 4000,
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        },
      })
    );
  }
};

export const checkErrorMessage = (error: any, dispatch: Dispatch) => {
  let message;
  try {
    message =
      error.response.data.message ||
      error.response.data.detail ||
      error.response.data.non_field_errors[0] ||
      "";
  } catch (error) {
    message = "";
  }

  if (message === "E-mail is not verified.") {
    dispatch({ type: AuthTypes.EMAIL_VERIFICATION_SENT });
  } else if (message !== "" && error.response.status !== 401) {
    dispatch(
      enqueueSnackbar({
        message: message,
        options: {
          variant: "error",
          autoHideDuration: 4000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        },
      })
    );
  } else if (error.response.status === 400) {
    let values: Array<string> = Object.values(error.response.data);
    if (values === null || values === undefined) return;

    for (let v of values) {
      dispatch(
        enqueueSnackbar({
          message: v,
          options: {
            variant: "error",
            autoHideDuration: 5000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          },
        })
      );
    }
  } else if (error.response.status === 500) {
    dispatch(
      enqueueSnackbar({
        message:
          "Internal Server Error. We are working to fix it as soon as possible.",
        options: {
          variant: "error",
          autoHideDuration: 6000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        },
      })
    );
  }
};

export const checkRequestTimeout = (error: any, dispatch: Dispatch) => {
  if (error.message === "TIMEDOUT") {
    dispatch(appFailure(AppTypes.REQUEST_TIMEDOUT));
    dispatch(
      enqueueSnackbar({
        message: "Check your internet connection",
        options: {
          variant: "warning",
          autoHideDuration: 5000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        },
      })
    );
  }
};
