import Skeleton from "@material-ui/lab/Skeleton";
import styled from "styled-components";

const Container = styled.div`
  color: inherit;

  h6 {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 5px;
    color: var(--black-400);
    width: max-content;
  }

  h1 {
    font-size: 16px;
    color: inherit;
  }
  .dt__link {
    transition: all 100ms ease-in-out;
    :active,
    :visited,
    :link {
      color: var(--blue);
    }
    :hover {
      color: var(--blue-60);
    }
  }
`;

const ItemDetail = ({ name, value, link, ...props }) => {
  return (
    <Container {...props}>
      <h6>{name}</h6>
      {link ? (
        // TODO: add an icon
        <a className="dt__link" href={value} target="_blank" rel="noreferrer">
          {value !== undefined ? (
            value.length > 0 ? (
              value
            ) : (
              "- - - - - - -"
            )
          ) : (
            <Skeleton />
          )}
        </a>
      ) : (
        <h1>
          {value !== undefined ? (
            value || value.length > 0 ? (
              value
            ) : (
              "- - - - - - -"
            )
          ) : (
            <Skeleton />
          )}
        </h1>
      )}
    </Container>
  );
};

export default ItemDetail;
