import { makeAsyncActionType } from "../utils";

const cpTypeGeneric = makeAsyncActionType("settings");

const GET = cpTypeGeneric("GET");
const UPDATE = cpTypeGeneric("UPDATE");

/**
 *
 * Actions Types
 *
 */

export const CompanyTypes = {
  UPDATE,
  GET,
};

/**
 *
 * Data Types
 *
 */

export interface Settings {
  allow_sh_transfer_shares: boolean;
  show_cp_logo: boolean;
  show_cp_name: boolean;
}

/**
 *
 * State Types
 *
 */

export interface SettingsState {
  loading: boolean;
  allow_sh_transfer_shares: boolean;
  show_cp_logo: boolean;
  show_cp_name: boolean;
  error: boolean | string | any;
}

/**
 * Settings thunk Types
 */

export interface UpdateSettingsParams {
  allow_sh_transfer_shares: boolean;
  show_cp_logo: boolean;
  show_cp_name: boolean;
}
