import { DropzoneArea } from "material-ui-dropzone";
import {
  AttachFile,
  Audiotrack,
  Description,
  PictureAsPdf,
  Theaters,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#1b1921",
    minHeight: 0,
    borderColor: "#446b9e",
    borderRadius: "10px",
    marginBottom: "8px",
  },
}));

const handlePreviewIcon = (fileObject, classes) => {
  const { type } = fileObject.file;
  const iconProps = {
    className: classes.image,
  };

  if (type.startsWith("video/")) return <Theaters {...iconProps} />;
  if (type.startsWith("audio/")) return <Audiotrack {...iconProps} />;

  switch (type) {
    case "application/msword":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return <Description {...iconProps} style={{ height: "40px" }} />;
    case "application/pdf":
      return <PictureAsPdf {...iconProps} style={{ height: "40px" }} />;
    default:
      return <AttachFile {...iconProps} style={{ height: "40px" }} />;
  }
};

const Upload = ({ onChange, ...props }) => {
  const classes = useStyles();

  return (
    <DropzoneArea
      showPreviews={true}
      showPreviewsInDropzone={false}
      getPreviewIcon={handlePreviewIcon}
      getFileAddedMessage={(fileName) => "File successfully added. "}
      getFileRemovedMessage={(fileName) => "File removed. "}
      previewGridProps={{ container: { spacing: 1, direction: "row" } }}
      previewChipProps={{ classes: { root: { minWidth: 160, maxWidth: 210 } } }}
      previewText="Selected file"
      classes={classes}
      onChange={onChange}
      maxFileSize={58570060}
      filesLimit={1}
      clearOnUnmount
      useChipsForPreview
      {...props}
    />
  );
};

export default Upload;
