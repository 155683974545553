import { makeAsyncActionType, makeActionType } from "../utils";

const appTypeGeneric = makeAsyncActionType("app");
const appAction = makeActionType("app");

const LOAD = appTypeGeneric("LOAD");
const CONTENT_LOAD_ERROR = appAction("CONTENT_LOAD_ERROR");
const REQUEST_TIMEDOUT = appAction("REQUEST_TIMEDOUT");

/**
 *
 * Actions Types
 *
 */

export const AppTypes = {
  LOAD,
  REQUEST_TIMEDOUT,
  CONTENT_LOAD_ERROR,
};

/**
 *
 * Data Types
 *
 */

export interface App {
  user: User;
  token: string;
}

export interface User {
  email: string;
  type: string;
  is_staff: string;
  is_active: string;
}

/**
 *
 * State Types
 *
 */

export interface AppState {
  loadingApp: boolean;
  contentLoadError: boolean;
  error: boolean | string | any;
}
