import { lazy } from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoutes";

const Login = lazy(() => import("../components/Auth/Login"));
const App = lazy(() => import("../components/App/App"));
const ResetPassword = lazy(() => import("../components/Auth/ResetPassword"));
const AddShareholder = lazy(() =>
  import("../components/Shareholders/AddShareholder")
);

export const MainRoutes = () => (
  <Switch>
    <Route
      path="/auth/password/reset/:uidb64/:token"
      component={() => <ResetPassword />}
    />
    <Route
      path="/auth/sh_register/:token"
      component={() => <AddShareholder />}
    />
    <Route path="/auth" component={() => <Login />} />
    <PrivateRoute path="/" component={() => <App />} />
  </Switch>
);

export default MainRoutes;
