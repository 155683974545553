import { makeAsyncActionType } from "../utils";

const cpTypeGeneric = makeAsyncActionType("transactions");

const GET = cpTypeGeneric("GET");
const GET_STOCK_DETAIL = cpTypeGeneric("GET_STOCK_DETAIL");
const CREATE = cpTypeGeneric("CREATE");
const ISSUE_STOCK = cpTypeGeneric("ISSUE_STOCK");
const DELETE = cpTypeGeneric("DELETE");
const UPDATE = cpTypeGeneric("UPDATE");
const GET_DETAIL = cpTypeGeneric("GET_DETAIL");
const APPROVE_TRANSFER = cpTypeGeneric("APPROVE_TRANSFER");
const DENY_TRANSFER = cpTypeGeneric("DENY_TRANSFER");
const GET_RELATED_TRANSFER = cpTypeGeneric("GET_RELATED_TRANSFER");
const SH_STOCK_DETAILS = cpTypeGeneric("SH_STOCK_DETAILS");
const TRANSFER = cpTypeGeneric("TRANSFER");

/**
 *
 * Actions Types
 *
 */

export const TransactionsTypes = {
  UPDATE,
  DELETE,
  ISSUE_STOCK,
  CREATE,
  GET,
  GET_STOCK_DETAIL,
  GET_DETAIL,
  APPROVE_TRANSFER,
  TRANSFER,
  SH_STOCK_DETAILS,
  GET_RELATED_TRANSFER,
  DENY_TRANSFER,
};

/**
 *
 * Data Types
 *
 */

export interface Stock {
  id: number | string;
  share_per_value: string | number;
  currency: string;
  share_class: string;
}

export interface Transfer {}

export interface Pagination {
  count: number;
  next: string;
  previous: string | null;
  results: Array<Transfer | any>;
}

/**
 *
 * State Types
 *
 */

export interface TransactionsState {
  loading: boolean;
  stock_details: object;
  error: boolean | string | any;
  stock: Stock | object | null;
  transfer: Transfer | object | null;
  transfers: Transfer[] | null;
  pagination: Pagination | any;
}

/**
 * Transactions thunk Types
 */

export interface CreateStockParams {}
