import { useEffect } from "react";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";

import { removeSnackbar } from "../../reducers/toast/actions";

let displayed = [];

const Toast = ({ notifications, removeSnackbar }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  useEffect(() => {
    notifications.forEach(
      ({ key, message, persist = false, options = {}, dismissed = false }) => {
        if (dismissed) {
          // dismiss snackbar using notistack
          closeSnackbar(key);
          return;
        }

        // do nothing if snackbar is already displayed
        if (displayed.includes(key)) return;

        // display snackbar using notistack
        enqueueSnackbar(message, {
          key,
          persist,
          ...options,
          onClose: (event, reason, myKey) => {
            if (options.onClose) {
              options.onClose(event, reason, myKey);
            }
          },
          onExited: (event, myKey) => {
            // remove this snackbar from redux store
            removeSnackbar(myKey);
            removeDisplayed(myKey);
          },
        });

        // keep track of snackbars that we've displayed
        storeDisplayed(key);
      }
    );
  }, [notifications, closeSnackbar, enqueueSnackbar, removeSnackbar]);

  return null;
};

const mapState = (state) => ({
  notifications: state.toast.notifications,
});

const mapDispatch = (dispatch) => ({
  removeSnackbar: (k) => dispatch(removeSnackbar(k)),
});

export default connect(mapState, mapDispatch)(Toast);
