import { action } from "typesafe-actions";
import { User } from "./types";

export const appRequest = (actionType: string) => action(actionType);
export const appSuccess = (user: User, actionType: string) =>
  action(actionType, { user });

export const appFailure = (
  actionType: string,
  error: boolean | string | any = false
) => action(actionType, error);
