import { makeActionType, makeAsyncActionType } from "../utils";

const authTypeGeneric = makeAsyncActionType("auth");
const appAction = makeActionType("auth");

const LOAD = authTypeGeneric("LOAD");
const LOGIN = authTypeGeneric("LOGIN");
const REGISTER = authTypeGeneric("REGISTER");
const REQUEST_RESET_PASSWORD = authTypeGeneric("REQUEST_RESET_PASSWORD");
const RESET_PASSWORD = authTypeGeneric("RESET_PASSWORD");
const CHANGE_PASSWORD = authTypeGeneric("CHANGE_PASSWORD");
const OPEN_FORGOT_PASS = authTypeGeneric("OPEN_FORGOT_PASS");
const CLOSE_FORGOT_PASS = authTypeGeneric("CLOSE_FORGOT_PASS");
const RESEND_VERIFICATION_EMAIL = authTypeGeneric("RESEND_VERIFICATION_EMAIL");
const EMAIL_VERIFICATION_SENT = appAction("EMAIL_VERIFICATION_SENT");
const CLOSE_EMAIL_VERIFICATION_SENT = appAction(
  "CLOSE_EMAIL_VERIFICATION_SENT"
);

/**
 *
 * Actions Types
 *
 */
export const AuthTypes = {
  LOAD,
  LOGIN,
  REGISTER,
  CHANGE_PASSWORD,
  REQUEST_RESET_PASSWORD,
  EMAIL_VERIFICATION_SENT,
  CLOSE_EMAIL_VERIFICATION_SENT,
  RESEND_VERIFICATION_EMAIL,
  RESET_PASSWORD,
  OPEN_FORGOT_PASS,
  CLOSE_FORGOT_PASS,
  AUTH_LOGOUT: "AUTH_LOGOUT",
  AUTH_REQUEST_UNAUTHORIZED: "AUTH_REQUEST_UNAUTHORIZED",
};

/**
 *
 * Data Types
 *
 */

export interface Auth {
  user: User;
  token: string;
}

export interface User {
  email: string;
  type: string;
  is_staff: string;
  is_active: string;
}

/**
 *
 * State Types
 *
 */

export interface AuthState {
  isAuthenticated: boolean;
  loading: boolean;
  user: User | object;
  error: boolean | string | any;
}

/**
 * Auth thunk Types
 */

export interface RegisterParams {
  name: string;
  email: string;
  password1: string;
  password2: string;
}

export interface LoginParams {
  email: string;
  password: string;
}
