import { Reducer } from "redux";
import { AppTypes } from "../app/types";
import { UserState, UserTypes } from "./types";

const INITIAL_STATE: UserState = {
  loading: false,
  user: {},
  users: [],
  globalInfo: {},
  error: false,
};

const companyReducer: Reducer<UserState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserTypes.GET.REQUEST:
    case UserTypes.CREATE.REQUEST:
    case UserTypes.DELETE.REQUEST:
    case UserTypes.UPDATE.REQUEST:
    case UserTypes.GET_DETAIL.REQUEST:
    case UserTypes.GLOBAL_INFO.REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case UserTypes.GET.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        users: action.payload,
      };
    case UserTypes.UPDATE.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case UserTypes.GET_DETAIL.SUCCESS:
    case UserTypes.CREATE.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        user: action.payload,
      };
    case UserTypes.DELETE.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case UserTypes.ACTIVATE.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case UserTypes.DEACTIVATE.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case UserTypes.GLOBAL_INFO.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        globalInfo: action.payload,
      };
    case UserTypes.GET.FAILURE:
    case UserTypes.GET_DETAIL.FAILURE:
    case UserTypes.GLOBAL_INFO.FAILURE:
    case UserTypes.CREATE.FAILURE:
    case UserTypes.DELETE.FAILURE:
    case UserTypes.UPDATE.FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case AppTypes.REQUEST_TIMEDOUT:
      return {
        ...state,
        loading: false,
        error: false,
      };
    default:
      return state;
  }
};

export default companyReducer;
