import { AnyAction, Dispatch } from "redux";
import { AuthTypes } from "../reducers/auth/types";
import { ApplicationState } from "../store";

export interface MiddlewareAPI<S, E extends AnyAction> {
  dispatch: Dispatch<E>;
  getState(): S;
}

export type Middleware<S, E extends AnyAction> = (
  store: MiddlewareAPI<S, E>
) => (next: Dispatch<E>) => (action: E) => ReturnType<Dispatch<E>>;

const saveTokenMiddleware: Middleware<ApplicationState, AnyAction> =
  (state) => (next) => (action) => {
    if (
      action.type === AuthTypes.LOAD.SUCCESS ||
      action.type === AuthTypes.LOGIN.SUCCESS
    ) {
      window.localStorage.setItem("token", action.payload.token);
    }
    if (
      action.type === AuthTypes.AUTH_REQUEST_UNAUTHORIZED ||
      action.type === AuthTypes.AUTH_LOGOUT
    ) {
      window.localStorage.removeItem("token");
    }
    return next(action);
  };

export default saveTokenMiddleware;
