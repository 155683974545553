import { Notification, ToastTypes } from "./types";

export const enqueueSnackbar = (notification: Notification) => {
  const key = notification.options && notification.options.key;

  return {
    type: ToastTypes.ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random(),
    },
  };
};

export const closeSnackbar = (key?: string | number) => ({
  type: ToastTypes.CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key,
});

export const removeSnackbar = (key?: string | number) => ({
  type: ToastTypes.REMOVE_SNACKBAR,
  key,
});
