export const makeAsyncActionType = (root: string) => (literal: string) => {
  return {
    REQUEST: `@${root}/${literal}_REQUEST`,
    SUCCESS: `@${root}/${literal}_SUCCESS`,
    FAILURE: `@${root}/${literal}_FAILURE`,
  };
};

export const makeActionType = (root: string) => (literal: string) => {
  return `@${root}/${literal}`;
};

export const TokensActions = {
  GET: "get",
  SET: "set",
  REMOVE: "remove",
};

export const performToken = (action = TokensActions.GET, token = "") => {
  switch (action) {
    case TokensActions.GET:
      return window.localStorage.getItem("token");
    case TokensActions.SET:
      return window.localStorage.setItem("token", token);
    case TokensActions.REMOVE:
      return window.localStorage.removeItem("token");
    default:
      return null;
  }
};
