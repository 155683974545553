import { makeActionType, makeAsyncActionType } from "../utils";

const cpTypeGeneric = makeAsyncActionType("companies");
const cpType = makeActionType("companies");

const GET = cpTypeGeneric("GET");
const CREATE = cpTypeGeneric("CREATE");
const DELETE = cpTypeGeneric("DELETE");
const UPDATE = cpTypeGeneric("UPDATE");
const GET_DETAIL = cpTypeGeneric("GET_DETAIL");
const ACTIVATE = cpType("ACTIVATE");
const DEACTIVATE = cpType("DEACTIVATE");
const GLOBAL_INFO = cpTypeGeneric("GLOBAL_INFO");
const UPLOAD_FILES = cpTypeGeneric("UPLOAD_FILES");
const SHARE_ACTIVATE = cpType("SHARE_ACTIVATE");
const SHARE_DEACTIVATE = cpType("SHARE_DEACTIVATE");
const COMPANY_GRAPH = cpTypeGeneric("COMPANY_GRAPH");

/**
 *
 * Actions Types
 *
 */

export const CompanyTypes = {
  UPDATE,
  DELETE,
  CREATE,
  GET,
  GET_DETAIL,
  ACTIVATE,
  DEACTIVATE,
  UPLOAD_FILES,
  COMPANY_GRAPH,
  SHARE_ACTIVATE,
  SHARE_DEACTIVATE,
  GLOBAL_INFO,
};

/**
 *
 * Data Types
 *
 */

export interface Company {
  id: number | string;
  name: string;
  incorporation_date: null;
  cp_registration_num: string | number;
  website: string;
  law_country: string;
  law_state: string;
  authorized_num_shares: string | number;
  share_per_value: string | number;
  currency: string;
  share_class: string;
  country: string;
  state: string;
  city: string;
  zip_code: string;
  address1: string;
  email: string;
  is_active: boolean;
  phone_number: string;
  cp_logo: string | null;
  cp_signature: string | null;
}

export interface CompanyUser {
  id: number | string;
  country: string;
  state: string;
  city: string;
  zip_code: string;
  address1: string;
  email: string;
  phone_number: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  date_birth: string;
  address2: string;
  user: string;
  company: string;
}

/**
 *
 * State Types
 *
 */

export interface CompanyState {
  loading: boolean;
  company: Company | null | object;
  companies: Company[] | null;
  graph: object | null;
  globalInfo: object | null;
  error: boolean | string | any;
}

/**
 * Company thunk Types
 */

export interface CreateCompanyParams {
  company: Company;
  authorized_person: CompanyUser;
}
