import { combineReducers } from "redux";

import auth from "./auth";
import app from "./app";
import users from "./users";
import toast from "./toast";
import companies from "./companies";
import transactions from "./transactions";
import documents from "./documents";
import settings from "./settings";
import shareholders from "./shareholders";

export default combineReducers({
  auth,
  app,
  users,
  toast,
  companies,
  shareholders,
  transactions,
  documents,
  settings,
});
