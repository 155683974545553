import { createSelector } from "reselect";

export const getAuthenticated = (state) => state.auth.isAuthenticated;
export const getAuthErrors = (state) => state.auth.error;

/** User related selectors */

const role = (state) => state.auth.user;

export const getRole = createSelector(role, (user) => {
  return user ? user.type : "";
});

const userInfo = (state) => state.auth.user;
export const getUserInfo = createSelector(userInfo, (info) => info);
