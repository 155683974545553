import { Reducer } from "redux";
import { AppTypes } from "../app/types";
import { DocumentState, DocumentTypes } from "./types";

const INITIAL_STATE: DocumentState = {
  loading: false,
  documents: [],
  document: {},
  error: false,
};

const companyReducer: Reducer<DocumentState> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case DocumentTypes.GET.REQUEST:
    case DocumentTypes.CREATE.REQUEST:
    case DocumentTypes.DELETE.REQUEST:
    case DocumentTypes.UPDATE.REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case DocumentTypes.GET.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        documents: action.payload,
      };
    case DocumentTypes.CREATE.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        documents: [action.payload, ...(state.documents || [])],
      };
    case DocumentTypes.DELETE.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        documents: state.documents?.filter((cp) => cp.id !== action.id),
      };
    case DocumentTypes.UPDATE.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        document: {
          ...state,
          ...action.payload.document,
        },
      };
    case DocumentTypes.GET.FAILURE:
    case DocumentTypes.CREATE.FAILURE:
    case DocumentTypes.DELETE.FAILURE:
    case DocumentTypes.UPDATE.FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case AppTypes.REQUEST_TIMEDOUT:
      return {
        ...state,
        loading: false,
        error: false,
      };
    default:
      return state;
  }
};

export default companyReducer;
