import { Reducer } from "redux";
import { AppState, AppTypes } from "./types";

const INITIAL_STATE: AppState = {
  loadingApp: false,
  contentLoadError: false,
  error: false,
};

const appReducer: Reducer<AppState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AppTypes.LOAD.REQUEST:
      return {
        ...state,
        loadingApp: true,
        contentLoadError: false,
      };
    case AppTypes.LOAD.SUCCESS:
      return {
        ...state,
        loadingApp: false,
        contentLoadError: false,
      };
    case AppTypes.LOAD.FAILURE:
      return {
        ...state,
        loadingApp: false,
        error: action.error,
        contentLoadError: false,
        message: "Something went wrong, we are solving it.",
      };
    case AppTypes.CONTENT_LOAD_ERROR:
      return {
        ...state,
        loadingApp: false,
        error: false,
        contentLoadError: true,
      };
    case AppTypes.REQUEST_TIMEDOUT:
      return {
        ...state,
        loading: false,
        error: false,
      };
    default:
      return state;
  }
};

export default appReducer;
